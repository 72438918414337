import React from 'react'
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import { Container, Row, Col } from 'reactstrap'
import Link from '../components/link'
import Box from '../components/box'
import styled from 'styled-components'
import Hero from '../components/Hero'
import Breadcrumb from "../components/breadcrumb"

import useSiteMetadata from '../hooks/use-site-config'
import { Helmet } from "react-helmet"

const BoxWrapper = styled(Box)`
margin-top: 3rem;
height: 200px;
padding-left: 2rem;
@media only screen and (max-width: 575px){
  border-left: none !important;
}
`

export const listQuery = graphql`
query ProceduresQuery($childrenPath: String) {
  allMarkdownRemark (filter: {fields: { slug: { regex: $childrenPath}}, frontmatter: {type: {eq: "procedure"}} }){
    edges {
      node {
        fields{
          slug
        }
        frontmatter {
          title
          description
          shortDescription
        }
      }
    }
  }
}
`;

function addClassIf(index) {
  let className;
  if (index % 2 === 0 && index % 3 === 0) {
    className="left_borderLess2 left_borderLess3"
  }else if (index % 2 === 0) {
    className= "left_borderLess2"
  }else if(index % 3 === 0) {
    className = "left_borderLess3"
  } 
  return className;
}

let Service = ({ title, link, description, shortDescription, className }) => (
  <Col xs="12" sm="6" md="4">
    <Link to={link} className="link">
      <BoxWrapper className={className}>
        <h4 className="mt-3 procedure_title">{title}</h4>
        {description && <p className="mt-1 procedure_short_description">{description}</p>}
        {shortDescription && <p className="mt-1 procedure_short_description">{shortDescription}</p>}
      </BoxWrapper>
    </Link>
  </Col>
)
export default function Procedures(props) {
  const { siteTitle } = useSiteMetadata()
  const postList = props.data.allMarkdownRemark;
  const fatherProcedure = props.pageContext.fatherProcedure;
  const title=fatherProcedure.title +" | "+ siteTitle;
  return (
    <Layout>
         <Helmet title={title}>
          <meta charSet="utf-8" />
        </Helmet>
      <Hero heroImg={fatherProcedure.cover.childImageSharp.fluid.src} classStyle="hero_procedure"/>
      <Container>
        <Row>
          {postList.edges.map(({ node }, i) => (
            <Service title={node.frontmatter.title} link={node.fields.slug}
              description={node.frontmatter.description} shortDescription={node.frontmatter.shortDescription}
              className={addClassIf(i)}/>
          ))}
        </Row>
      </Container>
      <hr></hr>
      <Breadcrumb pageContext={props.pageContext} label={fatherProcedure.title}/>
    </Layout>
  );
}